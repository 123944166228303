import { MenuItem } from "@mui/material";
import { memo } from "react";
import FormTextField from "../FormTextField";

interface POIItemDetailHeaderProps {
    name: string;
}

function POIItemDetailHeader({ name }: POIItemDetailHeaderProps) {
    return (
        <div>
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="Text"
                variant="outlined"
                name={`${name}.text`}
            />
            <FormTextField
                select
                sx={{ mt: 1 }}
                fullWidth
                label="Level"
                variant="outlined"
                name={`${name}.level`}
            >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
            </FormTextField>
        </div>
    );
}

export default memo(POIItemDetailHeader);
