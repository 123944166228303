import { memo } from "react";
import FormTextField from "../FormTextField";

interface POIItemDetailTextProps {
    name: string;
}

function POIItemDetailText({ name }: POIItemDetailTextProps) {
    return (
        <div>
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="Header"
                variant="outlined"
                name={`${name}.header`}
            />
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                multiline
                rows={5}
                label="Text"
                variant="outlined"
                name={`${name}.text`}
            />
        </div>
    );
}

export default memo(POIItemDetailText);
