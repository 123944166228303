import { memo, SyntheticEvent } from "react";
import { useWatch } from "react-hook-form";
import { Box, Button, Collapse, ListItemButton, ListItemText } from "@mui/material";
import POIItem from "./POIItem";

interface POIListItemButtonProps {
    id: string;
    index: number;
    name: string;
    onClick: (event: SyntheticEvent<HTMLElement>) => void;
    onDelete: (event: SyntheticEvent<HTMLElement>) => void;
    expanded: boolean;
}

function POIListItem({ id, index, name, onClick, onDelete, expanded }: POIListItemButtonProps) {
    const title = useWatch({ name: `${name}.title` });
    const poiId = useWatch({ name: `${name}.id` });

    return (
        <div>
            <ListItemButton onClick={onClick} data-id={id}>
                <ListItemText primary={title} secondary={poiId} />
            </ListItemButton>
            <Collapse in={expanded} mountOnEnter unmountOnExit>
                <Box sx={{ p: 1, backgroundColor: "#eee" }}>
                    <POIItem name={name} />
                    <Button
                        sx={{ mt: 1 }}
                        color="error"
                        variant="outlined"
                        onClick={onDelete}
                        data-index={index}
                    >
                        Delete POI
                    </Button>
                </Box>
            </Collapse>
        </div>
    );
}

export default memo(POIListItem);
