import { saveAs } from "file-saver";

import { InternalPOIFile, POIType } from "./types";

const sortPOIs = (pois: Array<POIType>) => {
    return pois.sort((a, b) => a.title.localeCompare(b.title));
};

export const importPOIFile = (text: string): InternalPOIFile => {
    const poiFile: InternalPOIFile = JSON.parse(text);
    sortPOIs(poiFile.pois);
    poiFile.pois.forEach((poi) => {
        // Add id if missing
        poi.id = poi.id ?? poi.title;

        // Map array of primitive values for react-hook-form
        poi.details?.forEach((detail) => {
            if (detail.type === "gallery") {
                const urls = detail.urls as any;
                detail.urls = urls?.map((url: string) => ({ url }));
            }
        });
        if (Array.isArray(poi.gallery?.urls)) {
            const urls = poi.gallery?.urls as any;
            poi.gallery = { urls: urls.map((url: string) => ({ url })) };
        }
    });
    return poiFile;
};

export const exportPOIFile = (poiFile: InternalPOIFile): string => {
    const copy = JSON.parse(JSON.stringify(poiFile));
    copy.version = Number(copy.version);
    copy.pois.forEach((poi: any) => {
        // Map array of primitive values from react-hook-form
        poi.details?.forEach((detail: any) => {
            if (detail.type === "gallery") {
                detail.urls = detail.urls?.map(({ url }: any) => url);
            }
        });
        if (Array.isArray(poi.gallery?.urls)) {
            poi.gallery = { urls: poi.gallery?.urls.map(({ url }: any) => url) };
        }
    });
    sortPOIs(copy.pois);
    const json = JSON.stringify(copy, null, 4);
    return json;
};

export const savePois = (poiFile: InternalPOIFile) => {
    const json = exportPOIFile(poiFile);
    const blob = new Blob([json], { type: "application/json" });
    saveAs(blob, "pois.json");
};
