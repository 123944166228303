import { memo } from "react";
import { useFieldArray } from "react-hook-form";
import FormTextField from "../FormTextField";

import { Box, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface POIItemDetailGalleryProps {
    name: string;
}

function POIItemDetailGallery({ name }: POIItemDetailGalleryProps) {
    const { fields, remove, append } = useFieldArray({ name: `${name}.urls` });
    return (
        <div>
            {fields.map((field, index) => (
                <Box key={field.id} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <FormTextField
                        sx={{ mt: 1, flexGrow: 1 }}
                        fullWidth
                        label="URL"
                        variant="outlined"
                        name={`${name}.urls.${index}.url`}
                    />
                    <div>
                        <IconButton color="error" onClick={() => remove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Box>
            ))}
            <Button sx={{ mt: 1 }} color="primary" onClick={() => append({ url: "" })}>
                Add URL
            </Button>
        </div>
    );
}

export default memo(POIItemDetailGallery);
