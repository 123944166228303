import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";

import { importPOIFile } from "../js/save-pois";
import { InternalPOIFile } from "../js/types";

interface DropzoneProps {
    onDrop: (file: InternalPOIFile) => void;
}

function Dropzone({ onDrop }: DropzoneProps) {
    const handleDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const file = acceptedFiles[0];
            if (file) {
                const text = await file.text();
                const poiFile = importPOIFile(text);
                onDrop(poiFile);
            }
        },
        [onDrop],
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/json": [".json"],
        },
        onDrop: handleDrop,
        multiple: false,
    });

    return (
        <Box sx={{ height: "100%" }}>
            <Box
                sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#777",
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <Typography>Drop POI file here or click to select</Typography>
            </Box>
        </Box>
    );
}

export default Dropzone;
