import { memo } from "react";
import { useWatch } from "react-hook-form";
import { POIDetailType } from "../js/types";

import POIItemDetailAudio from "./details/POIItemDetailAudio";
import POIItemDetailBubble from "./details/POIItemDetailBubble";
import POIItemDetailGallery from "./details/POIItemDetailGallery";
import POIItemDetailHeader from "./details/POIItemDetailHeader";
import POIItemDetailImage from "./details/POIItemDetailImage";
import POIItemDetailLink from "./details/POIItemDetailLink";
import POIItemDetailText from "./details/POIItemDetailText";
import POIItemDetailVideo from "./details/POIItemDetailVideo";
import POIItemDetailPOILink from "./details/POIItemDetailPOILink";

interface POIItemDetailContentProps {
    name: string;
}

function POIItemDetailContent({ name }: POIItemDetailContentProps) {
    const type: POIDetailType["type"] = useWatch({ name: `${name}.type` });

    let Component;
    switch (type) {
        case "text":
            Component = POIItemDetailText;
            break;
        case "header":
            Component = POIItemDetailHeader;
            break;
        case "image":
            Component = POIItemDetailImage;
            break;
        case "gallery":
            Component = POIItemDetailGallery;
            break;
        case "video":
            Component = POIItemDetailVideo;
            break;
        case "audio":
            Component = POIItemDetailAudio;
            break;
        case "link":
            Component = POIItemDetailLink;
            break;
        case "bubble":
            Component = POIItemDetailBubble;
            break;
        case "poi-link":
            Component = POIItemDetailPOILink;
            break;
        default:
            Component = null;
            break;
    }

    return Component && <Component name={name} />;
}

export default memo(POIItemDetailContent);
