import { memo } from "react";
import FormTextField from "../FormTextField";

interface POIItemDetailLinkProps {
    name: string;
}

function POIItemDetailLink({ name }: POIItemDetailLinkProps) {
    return (
        <div>
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="URL"
                variant="outlined"
                name={`${name}.url`}
            />
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="Text"
                variant="outlined"
                name={`${name}.text`}
            />
        </div>
    );
}

export default memo(POIItemDetailLink);
