import { memo, useState } from "react";
import { Box, Button, MenuItem, Select } from "@mui/material";
import { POIDetailType } from "../js/types";

const getDefaultValue = (type: POIDetailType["type"]): POIDetailType => {
    switch (type) {
        case "header":
            return { type, text: "", level: 1 };
        case "text":
            return { type, header: "", text: "" };
        case "image":
            return { type, url: "", description: "" };
        case "gallery":
            return { type, urls: [] };
        case "video":
            return { type, url: "" };
        case "audio":
            return { type, url: "" };
        case "link":
            return { type, url: "", text: "" };
        case "bubble":
            return { type, header: "", text: "" };
        case "poi-link":
            return { type, id: "", text: "" };
    }
};

interface POIItemDetailsAddButtonProps {
    onAdd: (defaultValue: POIDetailType) => void;
}
function POIItemDetailsAddButton({ onAdd }: POIItemDetailsAddButtonProps) {
    const [type, setType] = useState<POIDetailType["type"] | null>(null);

    const handleAdd = () => {
        if (type) {
            onAdd(getDefaultValue(type));
        }
        setType(null);
    };

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Select
                sx={{ width: 200 }}
                value={type ?? ""}
                onChange={(event) => setType(event.target.value as POIDetailType["type"])}
            >
                <MenuItem value="header">Header</MenuItem>
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="image">Image</MenuItem>
                <MenuItem value="gallery">Gallery</MenuItem>
                <MenuItem value="video">Video</MenuItem>
                <MenuItem value="audio">Audio</MenuItem>
                <MenuItem value="link">Link</MenuItem>
                <MenuItem value="bubble">Bubble</MenuItem>
                <MenuItem value="poi-link">POI Link</MenuItem>
            </Select>
            <Button color="primary" variant="outlined" disabled={type === null} onClick={handleAdd}>
                Add detail
            </Button>
        </Box>
    );
}

export default memo(POIItemDetailsAddButton);
