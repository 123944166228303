import { memo, SyntheticEvent, useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { List, Paper } from "@mui/material";
import POIItemDetail from "./POIItemDetail";
import POIItemDetailsAddButton from "./POIItemDetailsAddButton";

import {
    DndContext,
    DragEndEvent,
    useSensors,
    useSensor,
    TouchSensor,
    MouseSensor,
} from "@dnd-kit/core";
import { SortableContext } from "@dnd-kit/sortable";

import { POIDetailType } from "../js/types";

interface POIItemDetailsProps {
    name: string;
}

function POIItemDetails({ name }: POIItemDetailsProps) {
    const { fields, append, remove, move } = useFieldArray({ name });
    const handleAddDetail = useCallback(
        (defaultValue: POIDetailType) => {
            append(defaultValue);
        },
        [append],
    );
    const handleDeleteDetail = useCallback(
        (event: SyntheticEvent<HTMLElement>) => {
            const index = Number(event.currentTarget?.dataset?.index ?? undefined);
            if (!Number.isNaN(index)) {
                remove(index);
            }
        },
        [remove],
    );
    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event;

            if (active.id !== over?.id) {
                const from = fields.findIndex((field) => field.id === active.id);
                const to = fields.findIndex((field) => field.id === over?.id);
                if (from !== -1 && to !== -1) {
                    move(from, to);
                }
            }
        },
        [fields, move],
    );

    const sensors = useSensors(useSensor(TouchSensor), useSensor(MouseSensor));

    return (
        <List sx={{ p: 0 }}>
            <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
                <SortableContext items={fields}>
                    {fields.map((field, index) => (
                        <POIItemDetail
                            key={field.id}
                            id={field.id}
                            name={`${name}.${index}`}
                            index={index}
                            onDelete={handleDeleteDetail}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <Paper sx={{ p: 1, mt: 1 }}>
                <POIItemDetailsAddButton onAdd={handleAddDetail} />
            </Paper>
        </List>
    );
}

export default memo(POIItemDetails);
