import { memo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import FormCheckbox from "./FormCheckbox";
import FormTextField from "./FormTextField";
import POIItemDetails from "./POIItemDetails";
import POIItemDetailGallery from "./details/POIItemDetailGallery";

interface POIItemProps {
    name: string;
}

function POIItem({ name }: POIItemProps) {
    return (
        <Box>
            <Paper sx={{ p: 1 }}>
                <FormTextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Unique id"
                    variant="outlined"
                    name={`${name}.id`}
                />
                <FormTextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Title"
                    variant="outlined"
                    name={`${name}.title`}
                />
                <FormTextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Subtitle"
                    variant="outlined"
                    name={`${name}.subtitle`}
                />
                <FormTextField
                    sx={{ mt: 1 }}
                    fullWidth
                    label="Thumbnail URL"
                    variant="outlined"
                    name={`${name}.thumbnailURL`}
                />
                <FormCheckbox label="Lock geographically" name={`${name}.geolock`} />
                <Typography variant="overline" sx={{ mt: 1, mb: 0 }} component="p">
                    Header gallery
                </Typography>
                <POIItemDetailGallery name={`${name}.gallery`} />
            </Paper>
            <Typography sx={{ mt: 2 }} variant="h6">
                Details
            </Typography>
            <POIItemDetails name={`${name}.details`} />
        </Box>
    );
}

export default memo(POIItem);
