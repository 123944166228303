import { ReactNode } from "react";
import { useController } from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

type FormTextFieldProps = TextFieldProps & {
    name: string;
    children?: ReactNode;
};

function FormTextField({ name, children, ...props }: FormTextFieldProps) {
    const {
        field: { onChange, onBlur, value, ref },
    } = useController({ name, defaultValue: "" });

    return (
        <TextField
            onChange={onChange}
            onBlur={onBlur}
            value={value ?? ""}
            name={name}
            inputRef={ref}
            {...props}
        >
            {children}
        </TextField>
    );
}

export default FormTextField;
