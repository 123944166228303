import { memo } from "react";
import FormTextField from "../FormTextField";

interface POIItemDetailPOILinkProps {
    name: string;
}

function POIItemDetailPOILink({ name }: POIItemDetailPOILinkProps) {
    return (
        <div>
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="ID"
                variant="outlined"
                name={`${name}.id`}
            />
            <FormTextField
                sx={{ mt: 1 }}
                fullWidth
                label="Text"
                variant="outlined"
                name={`${name}.text`}
            />
        </div>
    );
}

export default memo(POIItemDetailPOILink);
