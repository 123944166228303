import { useState } from "react";
import Dropzone from "./Dropzone";
import POIForm from "./POIForm";

import { InternalPOIFile } from "../js/types";

function App() {
    const [poiFile, setPoiFile] = useState<InternalPOIFile | null>(null);

    return poiFile ? <POIForm poiFile={poiFile} /> : <Dropzone onDrop={setPoiFile} />;
}

export default App;
