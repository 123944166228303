import { Button, Container, Paper } from "@mui/material";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import POIList from "./POIList";
import { savePois } from "../js/save-pois";
import { InternalPOIFile } from "../js/types";
import FormTextField from "./FormTextField";

interface POIFormProps {
    poiFile: InternalPOIFile;
}
function POIForm({ poiFile }: POIFormProps) {
    const methods = useForm({ defaultValues: poiFile });
    const onSubmit = useCallback((data: InternalPOIFile) => {
        console.log(data);
        savePois(data);
    }, []);

    return (
        <FormProvider {...methods}>
            <Container maxWidth="md" sx={{ p: 1 }}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Paper>
                        <POIList />
                    </Paper>
                    <FormTextField
                        sx={{ mt: 1 }}
                        fullWidth
                        label="Version"
                        name="version"
                        type="number"
                    />
                    <Button sx={{ mt: 1 }} type="submit" variant="contained">
                        Save
                    </Button>
                </form>
            </Container>
        </FormProvider>
    );
}

export default POIForm;
