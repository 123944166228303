import { useController } from "react-hook-form";
import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";

type FormCheckboxProps = CheckboxProps & {
    name: string;
    label: string;
};

function FormCheckbox({ name, label, ...props }: FormCheckboxProps) {
    const {
        field: { onChange, value, ref },
    } = useController({ name, defaultValue: false });

    return (
        <FormControlLabel
            label={label}
            control={
                <Checkbox
                    onChange={onChange}
                    checked={value === true}
                    name={name}
                    inputRef={ref}
                    {...props}
                />
            }
        />
    );
}

export default FormCheckbox;
