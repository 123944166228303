import { memo, SyntheticEvent } from "react";
import { useWatch } from "react-hook-form";
import { Box, Button, Paper, Typography } from "@mui/material";
import DragHandle from "@mui/icons-material/DragHandle";
import POIItemDetailContent from "./POIItemDetailContent";

import { POIDetailType } from "../js/types";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const POIItemDetailTypeHeader = memo(({ name }: { name: string }) => {
    // Isolate rerender
    const type: POIDetailType["type"] = useWatch({ name: `${name}.type` });

    return <>{type}</>;
});

interface POIItemDetailProps {
    id: string;
    name: string;
    index: number;
    onDelete: (event: SyntheticEvent<HTMLElement>) => void;
}

function POIItemDetail({ id, name, index, onDelete }: POIItemDetailProps) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <Paper sx={{ p: 1, mt: 1 }} ref={setNodeRef} style={style} {...attributes}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ flexGrow: 1 }} variant="overline">
                    <POIItemDetailTypeHeader name={name} />
                </Typography>
                <Button color="error" onClick={onDelete} data-index={index}>
                    Delete
                </Button>
                <DragHandle sx={{ cursor: "grab" }} {...listeners} />
            </Box>
            <POIItemDetailContent name={name} />
        </Paper>
    );
}

export default memo(POIItemDetail);
