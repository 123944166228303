import { SyntheticEvent, useCallback, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { Fab, List } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import POIListItem from "./POIListItem";

function POIList() {
    const { fields, append, remove } = useFieldArray({ name: "pois" });

    const [expandedId, setExpandedId] = useState<string | null>(null);
    const handleExpansion = useCallback((event: SyntheticEvent<HTMLElement>) => {
        const newId = event.currentTarget?.dataset?.id ?? null;
        setExpandedId((id) => (newId === id ? null : newId));
    }, []);

    const handleAddPOI = useCallback(() => {
        append({
            id: "new-poi-id",
            title: "New POI",
            subtitle: "",
            gallery: { urls: [] },
            thumbnailURL: "",
            details: [],
        });
    }, [append]);
    const handleDeletePOI = useCallback(
        (event: SyntheticEvent<HTMLElement>) => {
            const index = Number(event.currentTarget?.dataset?.index ?? undefined);
            if (!Number.isNaN(index)) {
                remove(index);
            }
        },
        [remove],
    );

    return (
        <>
            <List>
                {fields.map((field, index) => (
                    <div key={field.id}>
                        <POIListItem
                            name={`pois.${index}`}
                            id={field.id}
                            index={index}
                            onClick={handleExpansion}
                            onDelete={handleDeletePOI}
                            expanded={expandedId === field.id}
                        />
                    </div>
                ))}
            </List>
            <Fab
                color="primary"
                onClick={handleAddPOI}
                sx={{ position: "fixed", right: 10, bottom: 10 }}
            >
                <AddIcon />
            </Fab>
        </>
    );
}

export default POIList;
